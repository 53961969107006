import React from 'react';
import logo from './logo.svg';
import './App.css';

class App extends React.Component {
  state = {
    color: 'yellow'
  }

  handleVote(color) {
    this.setState({
      color
    });
  }


  render() {
    return (
      <div style={{ width: 300, height: 450, border: '1px solid black', }}>
        <div style={
          {
            border: '1px solid black',
            backgroundColor: this.state.color,
            width: 300,
            height: 300,
            display: 'block',
            float: 'left'
          }
        }></div>
        <div onClick={() => this.handleVote('green')} style={{ backgroundColor: 'green', fontSize: 200, width: 149, height: 150, float: 'left', display: 'block', lineHeight: '114px' }}>+</div>
        <div onClick={() => this.handleVote('red')} style={{ paddingLeft: 50, backgroundColor: 'red', fontSize: 200, width: 99, height: 150, float: 'left', display: 'block', lineHeight: '114px' }}> -</div>
      </div>
    )
  }

}

export default App;
